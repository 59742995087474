<template>
  <div class="page-platforms" v-loading="fetchListLoading">
    <y-collapse @change="handleChange">
      <y-collapse-item v-for="item in list" :key="item.type" :name="item.type">
        <template slot="title">
          <div class="page-platforms__title">
            <i
              :class="[
                'page-platforms__arrow',
                activeNames.includes(item.type)
                  ? 'ui-icon-arrow-down-g'
                  : 'ui-icon-arrow-right-g'
              ]"
            ></i>
            <i :class="['page-platforms__logo', item.icon]"></i>
            <p class="page-platforms__name">
              {{ item.name }} ({{ item.list.length }})
            </p>
            <y-button
              class="page-platforms__add"
              size="small"
              type="primary"
              plain
              @click.stop="add(item)"
            >
              Add
            </y-button>
          </div>
        </template>
        <ul class="page-platforms__user-list">
          <li v-for="innerItem in item.list" :key="innerItem.id">
            <div class="page-platforms__user-head">
              <el-avatar
                v-if="innerItem.avatar"
                class="page-platforms__user-avatar"
                :src="innerItem.avatar"
              ></el-avatar>
              <i v-else class="page-platforms__user-avatar ui-icon-avatar"></i>
              <p class="page-platforms__user-name">{{ innerItem.name }}</p>
              <i
                class="page-platforms__user-delete ui-icon-platform-delete"
                @click="deletePlatformConfirm(innerItem)"
              ></i>
            </div>
            <div
              class="page-platforms__user-main"
              v-if="item.type === 'custom'"
            >
              <div class="page-platforms__user-main-item">
                Rtmp URL：
                {{ innerItem.serverUrl }}
                <span class="c-primary" v-copy="innerItem.serverUrl">Copy</span>
              </div>
              <div class="page-platforms__user-main-item">
                Rtmp Key：
                {{ innerItem.streamKey }}
                <span class="c-primary" v-copy="innerItem.streamKey">Copy</span>
              </div>
            </div>
          </li>
        </ul>
      </y-collapse-item>
    </y-collapse>
    <add-dialog ref="addDialogRef" @success="rtmpAddSuccess"></add-dialog>
    <facebook-dialog
      ref="facebookDialogRef"
      @sure="facebookDialogSure"
    ></facebook-dialog>
    <connect-dialog
      ref="connectDialogRef"
      @cancel="connectCancel"
      @sure="connectSure"
    ></connect-dialog>
    <delete-dialog ref="deleteDialogRef" @sure="deletePlatform"></delete-dialog>
  </div>
</template>

<script>
import { branchUrl } from '@config/env'
import AddDialog from './_add-dialog.vue'
import FacebookDialog from './_facebook-dialog'
import ConnectDialog from './_connect-dialog'
import DeleteDialog from './_delete-dialog.vue'
const mqttType = 'platform-bind'

export default {
  components: {
    AddDialog,
    FacebookDialog,
    ConnectDialog,
    DeleteDialog
  },
  data() {
    return {
      list: [
        {
          type: 'facebook',
          icon: 'ui-icon-platform-facebook',
          name: 'Facebook',
          list: [],
          bindId: 2
        },
        {
          type: 'youtube',
          icon: 'ui-icon-platform-youtube',
          name: 'Youtube',
          list: [],
          bindId: 1
        },
        {
          type: 'twitch',
          icon: 'ui-icon-platform-twitch',
          name: 'Twitch',
          list: [],
          bindId: 4
        },
        {
          type: 'custom',
          icon: 'ui-icon-platform-rtmp',
          name: 'RTMP',
          list: [],
          bindId: -1
        }
      ],
      activeNames: [],
      current: null
    }
  },
  computed: {
    mqttTopic() {
      return 'platform/' + this.$store.state.userInfo.userId
    },
    fetchListLoading() {
      return this.$store.state.loading['/overseas/user-platform/bind-list']
    }
  },
  mounted() {
    this.fetchList()
    this.subscribeAuthorizedPlatform()
  },
  beforeDestroy() {
    this.unSubscribeAuthorizedPlatform()
  },
  methods: {
    async fetchList() {
      const res = await this.$serve['user-platform'].bindList()
      if (res === this.$serve.FAIL) {
        return
      }
      this.list = this.list.map((item) => {
        return {
          ...item,
          list: res[item.type]
        }
      })
    },
    handleChange(activeNames) {
      this.activeNames = activeNames
    },
    add(current) {
      this.current = current
      if (current.type === 'facebook') {
        this.$refs.facebookDialogRef.show()
        return
      }
      if (current.type === 'custom') {
        this.$refs.addDialogRef.show()
        return
      }
      this.bind(current.bindId)
    },
    rtmpAddSuccess() {
      this.fetchList()
    },
    facebookDialogSure() {
      this.$refs.facebookDialogRef.hide()
      this.bind(this.current.bindId)
    },
    // 绑定平台
    async bind(platform) {
      const newWin = window.open(branchUrl + 'blank')
      const res = await this.$serve['user-platform'].bindPC({
        params: {
          platform
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$refs.connectDialogRef.show()
      newWin.location.href = res.authUrl
    },
    connectCancel() {
      this.current = null
    },
    // 连接确认
    connectSure() {
      if (!this.authorizedData || !this.authorizedData.result) {
        this.$message.error('Authorized Failed')
        return
      }
      this.$refs.connectDialogRef.hide()
    },
    subscribeAuthorizedPlatform() {
      this.$mqtt.on(
        this.mqttTopic,
        mqttType,
        this.subscribeAuthorizedPlatformCallback
      )
    },
    subscribeAuthorizedPlatformCallback(data) {
      const authorizedData = JSON.parse(data).data
      this.authorizedData = authorizedData
      if (!this.current) {
        return
      }
      if (
        authorizedData.result &&
        this.current.bindId === authorizedData.platform &&
        this.$refs.connectDialogRef.isShow()
      ) {
        this.$refs.connectDialogRef.hide()
        this.fetchList()
        this.$message.success('Authorized Success')
      }
    },
    unSubscribeAuthorizedPlatform() {
      this.$mqtt.off(
        this.mqttTopic,
        mqttType,
        this.subscribeAuthorizedPlatformCallback
      )
    },
    // 删除平台确认弹窗
    deletePlatformConfirm(item) {
      this.$refs.deleteDialogRef.show(item)
    },
    // 删除平台绑定的信息
    async deletePlatform(data) {
      const res = await this.$serve['user-platform'].unbind({
        params: {
          bindId: data.bindId,
          checked: data.checked
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$refs.deleteDialogRef.hide()
      this.fetchList()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  .el-collapse-item__header {
    line-height: 64px;
    height: 64px;
    background: #f7f8fa;
    border-bottom-color: rgba(0, 0, 0, 0.06);
  }
  .el-collapse-item__arrow {
    display: none;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-collapse-item__wrap {
    border-bottom: none;
  }
  .el-collapse-item {
    max-width: 1100px;
  }
  .el-button--primary.is-plain {
    background: transparent;
    width: 72px;
    &:hover,
    &:focus {
      background: #ff0043;
    }
  }
}
.page-platforms {
  padding: 24px 16px;
}
.page-platforms__title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px 0 24px;
}
.page-platforms__arrow {
  margin-right: 24px;
}
.page-platforms__logo {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.page-platforms__name {
  font-weight: 600;
  flex: 1;
}
.page-platforms__user-list {
  padding: 0 24px;
  li {
    padding: 16px 16px 16px 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
}
.page-platforms__user-head {
  display: flex;
  align-items: center;
}
.page-platforms__user-main {
  margin-top: 16px;
  padding: 0 0 0 48px;
  .com-copy-content {
    padding: 0;
  }
}
.page-platforms__user-main-item {
  display: flex;
  word-break: break-all;
  span {
    cursor: pointer;
    margin-left: 12px;
    white-space: nowrap;
  }
}
.com-copy-content {
  flex: 1;
}
.page-platforms__user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  margin-right: 16px;
}
.page-platforms__user-name {
  flex: 1;
  font-weight: 500;
}
.page-platforms__user-delete {
  cursor: pointer;
}
</style>
