<template>
  <div class="_connect-dialog">
    <y-dialog
      title="'Authorized Platform'"
      :visible.sync="visible"
      width="30%"
      @close="cancel"
    >
      <div class="_connect-dialog__body">
        <p>Please follow the steps to complete the platform authorization</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <y-button size="small" @click="cancel">Back</y-button>
        <y-button size="small" type="primary" @click="sure">Ok</y-button>
      </span>
    </y-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    cancel() {
      this.visible = false
      this.$emit('cancel')
    },
    sure() {
      this.$emit('sure')
    },
    isShow() {
      return this.visible
    }
  }
}
</script>

<style lang="scss" scoped>
._connect-dialog {
  ::v-deep .el-dialog {
    min-width: 280px;
    max-width: 504px;
  }
}
._connect-dialog__body {
  p {
    font-size: 14px;
    line-height: 20px;
    & + p {
      margin-top: 16px;
    }
  }
}
.screen-xs {
  ._connect-dialog {
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
