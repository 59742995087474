<template>
  <div class="_delete-dialog">
    <y-dialog :title="'Delete Platform'" :visible.sync="visible" width="30%">
      <div class="_delete-dialog__body">
        <p>
          Are you sure to delete platform
          <span>{{ currentPlatform }}</span>
          - Lil Ghost ?,
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <y-button size="small" @click="visible = false">Cancel</y-button>
        <y-button size="small" type="primary" @click="sure">Delete</y-button>
      </span>
    </y-dialog>
  </div>
</template>

<script>
const list = [
  {
    bindId: 2,
    platform: 'facebook',
    name: 'Facebook'
  },
  {
    bindId: 1,
    platform: 'youtube',
    name: 'YouTube'
  },
  {
    bindId: 4,
    platform: 'twitch',
    name: 'Twitch'
  },
  {
    bindId: 0,
    platform: 'rtmp',
    name: 'Custom RTMP'
  }
]

export default {
  data() {
    return {
      checked: false,
      visible: false,
      current: null
    }
  },
  computed: {
    currentPlatform() {
      if (!this.current) {
        return ''
      }
      const currentPlatformInfo = list.find(
        (item) => item.bindId === this.current.platform
      )
      if (!currentPlatformInfo) {
        return ''
      }
      return currentPlatformInfo.name
    }
  },
  methods: {
    show(current) {
      this.current = current
      this.checked = false
      this.visible = true
    },
    hide() {
      this.visible = false
      this.current = null
    },
    sure() {
      this.$emit('sure', {
        ...this.current,
        checked: this.checked
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._delete-dialog {
  ::v-deep .el-dialog {
    min-width: 280px;
    max-width: 504px;
  }
}
._delete-dialog__body {
  p {
    font-size: 14px;
    line-height: 20px;
    & + p {
      margin-top: 16px;
    }
  }
  ::v-deep .y-checkbox {
    display: flex;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.screen-xs {
  ._delete-dialog {
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
