<template>
  <div class="_add-dialog">
    <y-dialog
      title="Custom RTMP"
      :visible.sync="visible"
      width="504px"
      @closed="dialogClosed"
    >
      <y-pro-form
        ref="formRef"
        label-width="120px"
        label-position="top"
        :model="model"
        :fields="fields"
        :rules="rules"
        :submitter="{
          submitText: 'Add Platform',
          submitButtonProps: {
            size: 'small'
          },
          resetButtonProps: {
            style: {
              display: 'none'
            }
          }
        }"
        @submit="submit"
      ></y-pro-form>
    </y-dialog>
  </div>
</template>

<script>
import { rtmpReg } from '@utils/regex'
export default {
  data() {
    return {
      visible: false,
      model: {
        customName: '',
        serverUrl: '',
        streamKey: '',
        isAuth: false,
        authName: '',
        authPass: ''
      },
      fields: [],
      fixedFields: [
        {
          type: 'input',
          formItemProps: {
            label: 'Set RTMP name',
            prop: 'customName'
          },
          attributes: {
            placeholder: 'Set a name for the RTMP',
            size: 'small'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: 'Server URL',
            prop: 'serverUrl'
          },
          attributes: {
            placeholder: 'Go to “Stream Settings” , find the Server URL ',
            size: 'small'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: 'Stream key',
            prop: 'streamKey'
          },
          attributes: {
            placeholder: 'Go to "Stream Settings", find the Server Key',
            size: 'small'
          }
        },
        {
          type: 'checkbox',
          formItemProps: {
            prop: 'isAuth'
          },
          attributes: {
            size: 'small'
          },
          children: [
            {
              slot: 'default',
              render() {
                return 'Authentication'
              }
            }
          ]
        }
      ],
      dynamicFields: [
        {
          type: 'input',
          formItemProps: {
            label: 'User Account Of Destiantion',
            prop: 'authName'
          },
          attributes: {
            placeholder: 'Please enter the user account of destination',
            size: 'small'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: 'Password',
            prop: 'authPass'
          },
          attributes: {
            placeholder: 'Please enter the password',
            size: 'small'
          }
        }
      ],
      rules: {
        customName: [
          {
            required: true,
            message: 'Fill in current field',
            trigger: 'change'
          }
        ],
        serverUrl: [
          {
            required: true,
            message: 'Fill in current field',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Fill in current field'))
                return
              }
              if (!rtmpReg.test(value)) {
                callback(new Error('invaild serverUrl'))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        streamKey: [
          {
            required: true,
            message: 'Fill in current field',
            trigger: 'change'
          }
        ],
        authName: [
          {
            required: true,
            message: 'Fill in current field',
            trigger: 'change'
          }
        ],
        authPass: [
          {
            required: true,
            message: 'Fill in current field',
            trigger: 'change'
          }
        ]
      }
    }
  },
  watch: {
    'model.isAuth': {
      handler(val) {
        if (val) {
          this.fields = [...this.fixedFields, ...this.dynamicFields]
        } else {
          this.fields = [...this.fixedFields]
          this.model.authName = ''
          this.model.authPass = ''
        }
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      const res = await this.$serve.custom.saveCustom({
        data: this.model
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('add success')
      this.visible = false
      this.$emit('success')
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    dialogClosed() {
      this.$refs.formRef.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    padding: 24px 40px 18px 40px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-form-item__label {
    line-height: inherit;
  }
}
</style>
