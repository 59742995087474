var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_add-dialog"},[_c('y-dialog',{attrs:{"title":"Custom RTMP","visible":_vm.visible,"width":"504px"},on:{"update:visible":function($event){_vm.visible=$event},"closed":_vm.dialogClosed}},[_c('y-pro-form',{ref:"formRef",attrs:{"label-width":"120px","label-position":"top","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
        submitText: 'Add Platform',
        submitButtonProps: {
          size: 'small'
        },
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }},on:{"submit":_vm.submit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }