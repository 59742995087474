<template>
  <div class="_facebook-dialog">
    <y-dialog
      title="Facebook Account Binding"
      :visible.sync="visible"
      width="30%"
    >
      <div class="_facebook-dialog__body">
        <p>
          For YoloBox to show a viewer’s comment, name, and profile photo during
          live streams on Facebook,change the post privacy to Public when
          prompted by the, "Who can see YoloLiv\'s Posts on your timeline?" to
          enable this feature.
        </p>
        <p>
          Note:This feature is off by default due to Facebook’s privacy
          regulations.
        </p>
        <img src="@assets/images/img-facebook-bindnote.png" />
      </div>

      <span slot="footer" class="dialog-footer">
        <y-button size="small" type="primary" @click="sure">
          I know,start binding
        </y-button>
      </span>
    </y-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    sure() {
      this.$emit('sure')
    }
  }
}
</script>

<style lang="scss" scoped>
._facebook-dialog {
  ::v-deep .el-dialog {
    min-width: 280px;
    max-width: 504px;
  }
}
._facebook-dialog__body {
  text-align: center;
  p {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    & + p {
      margin-top: 8px;
    }
  }
  img {
    margin-top: 16px;
    width: 218px;
  }
}
.screen-xs {
  ._facebook-dialog {
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
